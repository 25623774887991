<template>
	<div class="monte_creneaux">
		<div class="form-row align-items-center">
			<div class="col-lg-12 col-xl-auto mr-auto">
				<label class="mb-0" :for="'creneau_' + index">{{ $t('monte.creneau') }} {{ index }}</label>
			</div>
			<div class="col">
	            <input
	                class="form-control"
	                v-model="form.start"
	                type="text"
	                required
	            />
			</div>
			<div class="col-auto">
	            {{ $t('global.a') }}
	        </div>
			<div class="col">
	            <input
	                class="form-control"
	                v-model="form.end"
	                type="text"
	                required
	            />
	        </div>
			<div class="col-auto">
	            <b-button @click="deleteCreneau" variant="secondary">
					<font-awesome-icon :icon="['fal', 'trash-alt']" />
				</b-button>
			</div>
			<div class="col-auto ml-auto">
				<div class="btn btn-secondary ml-auto order-md-last">
	                <font-awesome-icon :icon="['fal', 'sort-alt']" />
	            </div>
	        </div>
		</div>
	</div>
</template>

<script type="text/javascript">

	export default {
		name: '',
		props: ['index', 'delete_creneau', 'start', 'end', 'id'],
		mixins: [],
		data () {
			return {
				form: {
					start: null,
					end: null,
					id: null
				}
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				if(this.start) {
					this.form.start = this.start
					this.form.end = this.end
					this.form.id = this.id
				}
			},

			deleteCreneau() {
				this.$emit('update:delete_creneau', this.index)
			},

			getForm() {
				return this.form
			},
		},
	}

</script>